import React from 'react'
import PageRouter from './PageRouter'
import {pageLoaded, pageWillLoad} from 'src/shared/pageWrapper'
import {PHONE_SCREEN_SIZE, TABLET_SCREEN_SIZE} from 'src/sharedConstants'
import sharedState from 'src/shared/state/sharedState'
import {stateTools} from 'src/util/stateManager'
import 'src/shared/state/moduleLoader' // This needs to be loaded with all page.

pageLoaded.subscribe(() => {
  // Auto-scrolls to the url hash when the page loads.
  if (window.location.hash) {
    let $el
    try {
      $el = document.body.querySelector(window.location.hash) // Might return null if the id does not exist on the page
    } catch {} // If there's invalid characters in the query selector.
    $el && $el.scrollIntoView()
  }
})
// Scrolls the page back up to the top when a page changes.
pageWillLoad.subscribe(() => window.scrollTo(0, 0))

export default class App extends React.PureComponent {
  constructor(props) {
    super(props)

    let mobileMedia = matchMedia(`(max-width: ${PHONE_SCREEN_SIZE}px)`)
    mobileMedia.addListener(e => {
      sharedState.mobileView.set(e.matches)
    })
    sharedState.mobileView.set(mobileMedia.matches)

    let tabletMedia = matchMedia(`(max-width: ${TABLET_SCREEN_SIZE}px)`)
    tabletMedia.addListener(e => {
      sharedState.tabletView.set(e.matches)
    })
    sharedState.tabletView.set(tabletMedia.matches)

    if (document.readyState==='complete')
      sharedState.windowLoaded.set(true)
    else
      window.addEventListener('load', this.onLoad)
  }
  componentWillUnmount() {
    stateTools.removeListeners(this.stateListener)
    window.removeEventListener('load', this.onLoad)
  }
  onLoad = () => sharedState.windowLoaded.set(true)

  render() {
    return <PageRouter/>
  }
}
