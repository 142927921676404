import globals from 'src/util/globals'
import {State} from 'src/util/stateManager'
import {setNonPersistant} from './tools'

export default globals.sharedState = {
  // True after window.onLoad is fired.
  windowLoaded: new State(false),
  // True if the screensize is small. false otherwise.
  mobileView: new State(false),
  tabletView: new State(false),
  // Submission data of the footer's comment form.
  commentSubmissionStatus: setNonPersistant(new State({
    isSubmitted: false,
    hasResponded: false,
    submissionError: false,
  })),
}
