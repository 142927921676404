/* Wrapper for an article that provides features like the page header, and allows scrolling from one article to the next.

Properties: (All items optional)
  color: Page header color
  flush: Removes shadow on header if true
  headerChild: Element to inject into the page header if desired
  backgroundColor: Page's background color.
  mobileBackgroundColor: Background color of page when in mobile view
  pageBelow, pageAbove: Objects describing previous and next page.
      This is used to show a hint of these pages when transitioning to them.
      When not provided you can not scroll to another page.
      Possible values include color, title, and target (string containing the url)
*/


/* WARNING: This component has many edge cases to be aware of due to the complexity of its logic.
            If you ever change the component, please test these edge cases to make sure nothing broke.
            (Not all of these are currently working correctly but most of them are. Ideally we want them all working properly.)

Scrolling to a new page using keyboard navigation (arrow keys, page up/down, spacebar) - special logic was implemented to make these work.
Scrolling above the top of the page then clicking on a navigation link - It should undo the fake scrolling effect
Scrolling above the top of the page then using keyboard navigation - It should undo the fake scrolling effect
Scroll down until the page below's nav menu is visible. Use it.
Browser history buttons. This includes using them in the middle of page transitions.
  This can actually happen if the user attempts to navigate to a new page without internet. That user
  will be stuck indefinitally in the middle of a page transition. - As of the time of writting this is something that is broken.
Resizing the window, especially when near the bottom of the screen and are resizing from small to big.
  This includes the possibility that the tab was closed, the browser size was changed, and the tab was re-oppened, and the browser
  auto-scrolls the user back to where they left off at.
  Originally a window resize could cause the user to be close enough to the bottom to trigger the page transition.
Handling page transitions without internet
The user triggering a page transition by scrolling with the scroll bar. If the user holds on to the scrollbar,
  they may force multiple successive page transitions.
A user comming to the page with a url that has an anchor tag. - This should bring them to a component with that id.
*/

import React from 'react'
import {CSSTransition} from "react-transition-group"
import {withRouter} from 'react-router-dom'
import Header from './Header'
import {pageLoaded, pageWillUnload, requestingPage} from 'src/shared/pageWrapper'
import PagePlaceholder from 'src/shared/PagePlaceholder'
import {state as sharedState} from 'src/shared/logic'
import {stateTools} from 'src/util/stateManager'
import util from 'src/util/util'
import 'src/css/App/ArticleFrame.css'
const {CustomEvent} = util

const ABOVE_SCROLL_ANIMATION_TIME = '400ms'
const ABOVE_SCROLL_DISTANCE = 6
const DOWN_TRANS_JUMP_AREA = 80 // Mobile devices need this to be a bigger number to work. (Like Chrome on Android)
const DOWN_TRANS_EFFECT_AREA = 400
const DOWN_TRANS_SCROLL_AREA = DOWN_TRANS_JUMP_AREA + DOWN_TRANS_EFFECT_AREA + 20
let hasInitialScrollHappened = false

export default class ArticleFrame extends React.Component {
  stateListener = () => this.forceUpdate()
  render() {
    let updater = this.stateListener
    stateTools.gotUpdated(updater)
    return (
      <ArticleFrameEl {...this.props}
      mobileView={sharedState.mobileView.use(updater)}
      />
    )
  }
  componentWillUnmount() {
    stateTools.removeListeners(this.stateListener)
  }
}

class ArticleFrameEl extends React.Component {

  state = {
    isTransitioning: false,
    transitionDirection: null, // null if there currently is no transition.
    showLoading: false,

    // Down transition logic
    scrollDistanceFromBottom: Infinity, // Distance from bottom of page. Set to Infinity when unknown

    // Up transition state
    upTransPhase: null, // Which phase of the transition-up animation are we in? null if we aren't.
    scrollDistancePastTop: 0, // How far are we scrolled above the page?
    scrollDistancePastTop_transition: true, // When scrollDistancePastTop changes, should a smooth transition happen?
  }

  constructor(props) {
    super(props)
    this.$el = null
    this.$pageContent = null

    // Down transition vars
    this.$downTransBubble = null
    this.$downTransHeader = null
    this.$downTransZone = null
    this.downBubbleTransitionEnd = new util.CustomEvent()
    this.scrollObserver = new IntersectionObserver(([entity]) => {
      if (entity.isIntersecting)
        window.addEventListener('scroll', this.downTransOnScroll)
      else
        window.removeEventListener('scroll', this.downTransOnScroll)
    })

    // Up transition vars
    this.$upTransTitle = null
    this.$upTransPageCover = null
    this.upOverlayTransitionEnd = new util.CustomEvent()
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener('popstate', this.onPopState)

    // Down transition logic
    window.addEventListener('resize', this.onResize)

    // Up transition logic
    window.addEventListener('mousewheel', this.onMouseWheel, {passive:false}) // Chrome support
    window.addEventListener('DOMMouseScroll', this.onMouseWheel, {passive:false}) // Firefox support
    window.addEventListener('scroll', this.upTransOnScroll)
    window.addEventListener('keydown', this.onKeyDown)
    pageLoaded.subscribe(() => {
      if (this.state.transitionDirection !== 'up')
        this.setState({scrollDistancePastTop:0, scrollDistancePastTop_transition:true})
    }, this)
    pageWillUnload.subscribe(() => {
      if (this.state.transitionDirection !== 'down')
        // Makes sure transition bubble goes away. (Sometimes it doesn't when clicking on navigation inside of it)
        this.setStateSilently({scrollDistanceFromBottom: Infinity}, {cssUpdate:['TRANS_BELOW']})
    }, this)
  }
  componentWillUnmount(props) {
    this._isMounted = false
    window.removeEventListener('popstate', this.onPopState)

    // Down transition logic
    window.removeEventListener('scroll', this.downTransOnScroll)
    window.removeEventListener('resize', this.onResize)

    // Up transition logic
    window.removeEventListener('mousewheel', this.onMouseWheel)
    window.removeEventListener('DOMMouseScroll', this.onMouseWheel)
    window.removeEventListener('scroll', this.upTransOnScroll)
    window.removeEventListener('keydown', this.onKeyDown)
    CustomEvent.unsubscribeFromAllByOwner(this)
  }

  render() {
    let color = this.props.color || 'transparent'
    let background = (this.props.mobileView && this.props.mobileBackgroundColor) || this.props.backgroundColor
    return (
      <div className='ArticleFrame' ref={el=>this.$el=el}>

        <div className='article-background' style={{background}}/>
        <meta name='theme-color' content={color}/>
        {this.renderDownTransCover()}

        <div className='page-content' ref={el=>this.$pageContent=el} style={this.pageContentStyle()}>
          {this.renderHeader(color)}
          {this.props.children}
          {this.props.pageBelow && (
            <div style={{height:DOWN_TRANS_SCROLL_AREA+'px'}}
            ref={$el=>$el ? this.scrollObserver.observe($el) : this.scrollObserver.disconnect()}/>
          )}
        </div>

        {this.renderUpTransOverlay()}
        {this.props.pageAbove && this.renderUpTransTitle()}
        {this.props.pageBelow && this.renderDownTransBubble()}
        {this.state.showLoading && <PagePlaceholder/>}

      </div>
    )
  }
  pageContentStyle = () => (
    this.state.scrollDistancePastTop_transition ? {
      transform: this.pastTopFactor()===0 ? 'unset' : `translateY(${this.pastTopFactor()**0.8 *120}px)`,
      transitionDuration: '400ms',
      transitionProperty: 'transform',
    } : {}
  )

  renderHeader = color => (
    <Header extendBackgroundAbove headerChild={this.props.headerChild} mainColor={
      /* When transitioning down we switch the header color early to prevent an occasional 1-frame color flash when the transition ends. */
      this.state.transitionDirection==='down' ? this.props.pageBelow.color : color
    } flush={this.props.flush}/>
  )

  /* Down transition renderings */

  renderDownTransBubble = () => (
    <div className='downtrans-bubble' ref={el=>this.$downTransBubble=el}
    style={this.downTransBubbleStyle()} onTransitionEnd={this.downBubbleTransitionEnd.trigger}>
      <h1 className='downtrans-title'>{this.props.pageBelow.title}</h1>
      <div className='downtrans-header' ref={el=>this.$downTransHeader=el}>
        <Header transitionHeader location={this.props.pageBelow.target}/>
      </div>
    </div>
  )
  downTransBubbleStyle = () => {
    if (this.state.transitionDirection==='down') {
      let bubblePos = this.$downTransBubble.getBoundingClientRect().top
      let transitionHeaderPos = this.$downTransHeader.getBoundingClientRect().top
      return {
        width: 60+150 +'%',
        height: `calc(100% + ${transitionHeaderPos-bubblePos}px)`,
        bottom: 0,
        borderRadius: 0,
        backgroundColor: this.props.pageBelow.color,
        transitionProperty: 'height, bottom, border-radius',
        transitionDuration: '400ms',
      }
    } else if (this.state.scrollDistanceFromBottom < DOWN_TRANS_JUMP_AREA+DOWN_TRANS_EFFECT_AREA) {
      let nearFactor = 1 - this.state.scrollDistanceFromBottom / (DOWN_TRANS_JUMP_AREA+DOWN_TRANS_EFFECT_AREA)
      return {
        width: 60+ nearFactor**1.5 *150 +'%',
        height: nearFactor**1.5 *650 +'px',
        bottom: -nearFactor*150 +'px',
        backgroundColor: this.props.pageBelow.color,
      }
    } else {
      return {display: 'none'}
    }
  }

  renderDownTransCover = () => (
    <CSSTransition classNames='fadeaway' in={this.state.transitionDirection==='down'}
    addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}>
      <div className='downtrans-page-cover' style={{background:this.props.color}}/>
    </CSSTransition>
  )

  /* Up transition renderings */

  renderUpTransOverlay = () => (
    <div className={'uptrans-page-cover'+(this.state.upTransPhase==='HIDDEN'?' loading-page':'')}
    ref={el=>this.$upTransPageCover=el} onTransitionEnd={this.upOverlayTransitionEnd.trigger}
    style={this.upTransOverlayStyle()}>
      <p>Loading {this.props.title&&this.props.title.toLowerCase()} page...</p> {/*Shows up if it takes a while to load*/}
    </div>
  )
  upTransOverlayStyle = () => (
    this.state.upTransPhase!=='REVEALING' ? {
      opacity: this.state.upTransPhase==='HIDDEN' ? 1 : this.pastTopEffectFactor(),
      transition: `opacity ${ABOVE_SCROLL_ANIMATION_TIME}`,
    } : {
      opacity: 0,
      transition: 'opacity 2000ms',
    }
  )

  renderUpTransTitle = () => (
    <h1 className='uptrans-title' ref={el=>this.$upTransTitle=el}
    style={this.upTransTitleStyle()}>
      {this.props.pageAbove.title}
    </h1>
  )
  upTransTitleStyle = () => {
    let effectFactor = this.pastTopEffectFactor()
    return {
      transform: `translateX(-50%) translateY(${10+effectFactor*30}px)`,
      opacity:
        this.state.scrollDistancePastTop<ABOVE_SCROLL_DISTANCE
        ? Math.min(effectFactor*1.5, 1) : 0,
      transitionProperty: 'opacity, transform',
      transitionDuration: ABOVE_SCROLL_ANIMATION_TIME,
    }
  }

  pastTopFactor = () => this.state.scrollDistancePastTop/ABOVE_SCROLL_DISTANCE
  // The point when visual queues start appearing to indicate a transition.
  pastTopEffectFactor = () => {
    let factor = this.pastTopFactor()
    return factor > 0.3 ? (factor-0.3)/.7 : 0
  }

  setStateSilently(stateChanges, {cssUpdate=[]}={}) {
    // Sets the state without triggering a rerender
    Object.assign(this.state, stateChanges)
    this._updateCSS(cssUpdate)
  }
  componentDidUpdate = () => this._updateCSS()
  _updateCSS(cssUpdate=['TRANS_BELOW','TRANS_ABOVE']) {
    if (cssUpdate.includes('TRANS_BELOW')) {
      if (this.$downTransBubble)
        this._updateStyle(this.$downTransBubble, this.downTransBubbleStyle())
    }
    if (cssUpdate.includes('TRANS_ABOVE')) {
      this._updateStyle(this.$pageContent, this.pageContentStyle())
      if (this.$upTransPageCover)
        this._updateStyle(this.$upTransPageCover, this.upTransOverlayStyle())
      if (this.$upTransTitle)
        this._updateStyle(this.$upTransTitle, this.upTransTitleStyle())
    }
  }
  _updateStyle(el, styleObj) {
    let undoer = {} // This makes sure things that were previously set will be unset.
    for (let key of el.style)
      undoer[key] = null
    Object.assign(el.style, undoer, styleObj)
  }

  onPopState = e => {
    // When the user navigates in history during a transition, we want to make sure we cancel any transitions.
    this.setState({isTransitioning: false, transitionDirection: null, upTransPhase: null})
  }

  /* Down transition logic */

  downTransOnScroll = e => !this._lockOnScroll && this.handleDownTransEvent(e)
  _lockOnScroll = null
  onResize = async e => {
    // Sometimes when resizing it might call onResize() a couple times, but with an
    // onScroll event in between, causing unexpected behavior. This is why we disable
    // onScroll for a timeout. (This behavior was seen in chrome)
    let lock = this._lockOnScroll = {}
    this.handleDownTransEvent(e)
    await new Promise(r=>setTimeout(r,200))
    if (lock === this._lockOnScroll) // Might be false if onResize() was called again during the timeout.
      this._lockOnScroll = null
  }
  handleDownTransEvent(e) {
    // The content of this event listener is in charge of animating the down transition bubble

    if (!this.props.pageBelow || this.state.isTransitioning || requestingPage.eventState.value)
      return

    let documentHeight = this.$el.getBoundingClientRect().height
    let screenHeight = document.body.offsetHeight
    let scrollPos = window.pageYOffset
    let scrollDistanceFromBottom = documentHeight - scrollPos - screenHeight

    if (scrollDistanceFromBottom < DOWN_TRANS_JUMP_AREA) {
      if (e.type === 'resize' || !hasInitialScrollHappened) {
        hasInitialScrollHappened = true // This variable protects against the edge case of re-opening a tab with a different window size
        scrollDistanceFromBottom = DOWN_TRANS_JUMP_AREA
        window.scrollTo(0, documentHeight - scrollDistanceFromBottom - screenHeight)
      } else if (e.type === 'scroll') {
        this.animateToPageBelow()
        return
      }
    }
    this.setStateSilently({scrollDistanceFromBottom}, {cssUpdate:['TRANS_BELOW']})
  }
  async animateToPageBelow() {
    let loadPage = this.props.pageBelow.preload()
    this.setState({scrollDistanceFromBottom:Infinity, isTransitioning: true, transitionDirection: 'down'})
    await this.downBubbleTransitionEnd.onNextTrigger() // downBubbleTransitionEnd should be triggered by the state change.

    if (this.state.transitionDirection!=='down') // TODO: Still needed? Maybe if back button is pushed during transition and state has changed?
      return
    this.props.lockProps() // Prevents page flicker while loading.
    this.props.history.push(this.props.pageBelow.target)
    this.setState({showLoading: true})
    await loadPage
    this.setState({isTransitioning: false, transitionDirection: null, showLoading: false})
    this.props.unlockProps()
  }

  /* Up transition logic */

  onMouseWheel = (()=>{
    // The content of this event listener is in charge of animating the up transition
    // A major difficulty with this function is supporting different scroll devices.

    // It seems there's no reliable way of knowing how much a single "onMouseWheel"
    // event should move the screen. Some mice only trigger these evens rarely, but expect
    // a lot of screen movement, while touchpads trigger them often expecting little movement.
    // The current solution is to make it work best with mice, and try to do some patch-work to
    // make it work ok on a laptop.

    // f() is the actuall callback. The rest of the logic creates a queue and calls
    // everything in the queue, sometimes at a delay, to make sure the transitions are
    // still visible, even when they would normally fly by too quickly (because a touchpad did the scrolling)
    let pendingCalls = []
    let lastScollTime = 0

    const f = async e => {
      let isMovingUp = e.wheelDelta ? e.wheelDelta>0 : e.detail<0
      if (isMovingUp && window.pageYOffset===0) {
        // This prevents accedental scroll past the top, by enforcing a delay once the top is reached.
        if (Date.now()-lastScollTime < 200)
          return
        this.updateScrollDistancePastTop(this.state.scrollDistancePastTop+1)
        // This delays the next call to f(), which delays the whole scroll above top effect.
        await new Promise(r=>setTimeout(r, 30))
      } else {
        lastScollTime = Date.now()
        pendingCalls = []
      }
    }

    let isWorking = false
    const startWorker = async () => {
      isWorking = true
      while (pendingCalls.length) {
        if (!this._isMounted) break
        await pendingCalls.shift()()
      }
      isWorking = false
    }
    return e => {
      if (this.state.isTransitioning) {
        pendingCalls = []
        e.preventDefault()
        return false
      }
      if (!this.props.pageAbove)
        return

      pendingCalls.push(()=>f(e))
      if (!isWorking)
        startWorker()
    }
  })()
  upTransOnScroll = () => {
    // If this ever fires, we'll know its not for scrolling past the top, so we'll
    // make sure to undo related effects.
    // We do this in onScroll instead of onMouseWheel because onScroll handles the
    // case where you drag the scrollbar.
    if (this.state.scrollDistancePastTop===0)
      return
    this.updateScrollDistancePastTop(0)
  }

  onKeyDown = e => {
    if (this.state.isTransitioning && ['PageUp','PageDown','ArrowUp','ArrowDown',' '].includes(e.key)) {
      e.preventDefault()
      return false
    }
    if (!this.props.pageAbove || window.pageYOffset!==0)
      return

    if (e.key === 'PageUp')
      this.updateScrollDistancePastTop(ABOVE_SCROLL_DISTANCE)
    else if (e.key === 'ArrowUp')
      this.updateScrollDistancePastTop(this.state.scrollDistancePastTop+2)
    else if (e.key === 'ArrowDown' || e.key === 'PageDown' || e.key === ' ')
      this.updateScrollDistancePastTop(0)
  }

  updateScrollDistancePastTop(newDistance) {
    if (newDistance>ABOVE_SCROLL_DISTANCE)
      newDistance = ABOVE_SCROLL_DISTANCE

    this.setStateSilently({scrollDistancePastTop: newDistance, scrollDistancePastTop_transition: true}, {cssUpdate:['TRANS_ABOVE']})
    if (newDistance===ABOVE_SCROLL_DISTANCE && !this.state.isTransitioning)
      this.animateToPageAbove()
  }
  async animateToPageAbove() {
    let loadPage = this.props.pageAbove.preload()
    this.setState({isTransitioning: true, transitionDirection: 'up', upTransPhase: 'HIDING'})
    await this.upOverlayTransitionEnd.onNextTrigger() // upOverlayTransitionEnd should be triggered by the state change.

    this.setState({scrollDistancePastTop: 0, scrollDistancePastTop_transition: false, upTransPhase: 'HIDDEN'})
    this.props.history.push(this.props.pageAbove.target)
    // At this point if the user looses internet the new page might not load. This promise
    // also resolves if the user navigated backwards in history while waiting. We need to watch out for this case.
    await loadPage
    await new Promise(r=>setTimeout(r)) // When the browser back button is used, this will give onPopState() a chance to be called and to change the state

    if (this.state.upTransPhase !== 'HIDDEN') // Happens when the browser back button is used.
      return
    this.setState({upTransPhase: 'REVEALING'})

    const scrollDistance = 250
    let finalScrollPos = document.body.scrollHeight - document.body.clientHeight - DOWN_TRANS_SCROLL_AREA
    await util.scrollTo(finalScrollPos, 800, {startAt: finalScrollPos+scrollDistance, reevalute: true})

    this.setState({isTransitioning: false, transitionDirection: null, upTransPhase: null})
  }
}
ArticleFrameEl = util.providePropLocker(withRouter(ArticleFrameEl))
