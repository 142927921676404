import React from 'react'
import Loadable from 'react-loadable'
import util from 'src/util/util'
const {CustomEvent, MountObserver} = util

// Fires after rendering a page that just changed.
export let pageLoaded = new CustomEvent()
// Fires just before a page loads.
export let pageWillLoad = new CustomEvent()
// Fires just before a page unloads.
export let pageWillUnload = new CustomEvent()

// Fires with a bool indicating that we started/stopped waiting on a page to load.
// Does not fire when changing to an already loaded page, or while preloading a page.
export let requestingPage = new CustomEvent({value:false})

export default function pageWrapper({loader, loading:Loading=null}={}) {
  return Loadable({
    loader,
    render: (module, props) => (
      <MountObserver
      didMount={()=>pageLoaded.trigger()}
      willMount={()=>pageWillLoad.trigger()}
      willUnmount={()=>pageWillUnload.trigger({value:false})}>
        <module.default {...props}/>
      </MountObserver>
    ),
    loading: () => (
      <MountObserver
      didMount={()=>requestingPage.trigger({value:true})}
      willUnmount={()=>requestingPage.trigger({value:false})}>
        <Loading/>
      </MountObserver>
    )
  })
}
