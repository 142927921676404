// General utility functions

import React from 'react'
import ReactDOM from 'react-dom'

let util = {}
export default util

// Wraps callback in a function that prevents callback from being called more
// than once every "time" milliseconds.
// The return value of the callback will not be returned by this wrapper.
util.debounce = (time, callback) => {
  let intervalID = null
  let attemptedCallDuringInterval = false
  return (...args) => {
    if (intervalID!==null) { // Supresses repeated calls
      attemptedCallDuringInterval = true // Sets this flag to make the callback get called during the interval.
      return
    }

    callback(...args)
    intervalID = setInterval(() => {
      if (attemptedCallDuringInterval) {
        attemptedCallDuringInterval = false
        callback(...args)
      } else {
        clearInterval(intervalID)
        intervalID = null
      }
    }, time)
  }
}

// Scrolls to the specific element in the timeout given.
// target can either be an element or a number. If its a number, alignTo and reevalute become irrelavent.
// duration is how long it scrolls for
// options:
//   alignTo can be either 'top', 'middle', or 'bottom' of the screen
//   startAt is a distance from the top where the scroll animation should start. (Defaults to current scroll position)
//   reevaluate can be set to true if it's expected that the object you're scrolling to is also moving.
//   ease can be set to either true or false, default is true.
// Returns a promise.
util.scrollTo = async (target, duration=500, {alignTo='top', reevaluate=false, startAt=null, ease=true}={}) => {
  const FRAME_RATE = 50
  let startingScrollPos = startAt==null ? document.documentElement.scrollTop : startAt
  function evaluateDistance() {
    let boundingBox = target.getBoundingClientRect()
    if (alignTo==='top')
      return boundingBox.top
    else if (alignTo==='middle')
      return boundingBox.top+boundingBox.height/2 - window.innerHeight/2
    else if (alignTo==='bottom')
      return boundingBox.bottom-window.innerHeight
    else throw new Error()
  }

  let remainingDistance
  if (typeof target==='number') {
    remainingDistance = target - startingScrollPos
    reevaluate = false // If target is a number, then the value of this should not make a difference, so we'll force it false.
  } else
    remainingDistance = evaluateDistance()

  let totalFrames = FRAME_RATE*duration/1000
  let framesLeft = totalFrames
  let currentPos = startingScrollPos
  return new Promise(resolve => {
    let interval = setInterval(() => {
      if (framesLeft === 0) {
        clearInterval(interval)
        resolve()
        return
      }

      let incrementAmount = remainingDistance/framesLeft
      if (ease)
        incrementAmount *= 2 - 1/framesLeft

      if (reevaluate)
        remainingDistance = evaluateDistance()
      else
        remainingDistance -= incrementAmount
      currentPos += incrementAmount
      document.documentElement.scrollTop = currentPos
      framesLeft--
    }, 1000/FRAME_RATE)
  })
}

util.CustomEvent = class {
  // This set only contains those objects who has subscribers. This lets forgotten CustomEvents with no subscribbers be garbaged collected.
  static _allActiveEvents = new Set()
  constructor(eventState={}) {
    this._observers = []
    this.eventState = eventState
  }

  // owner is a unique object. Later you can call unsubscribeByOwner() to unsubscribe all listeners
  // that were subscribed with that owner. Useful to make cleanup easier when a component unmounts,
  // just use the component instance as the owner.
  subscribe(f, owner=null) {
    this._observers.push({observer:f, owner})
    util.CustomEvent._allActiveEvents.add(this)
  }
  unsubscribe(f) {
    this._observers = this._observers.filter(obs=>obs.observer!==f)
    if (this._observers.length===0)
      util.CustomEvent._allActiveEvents.delete(this)
  }
  unsubscribeByOwner(owner) {
    this._observers = this._observers.filter(obs=>obs.owner!==owner)
    if (this._observers.length===0)
      util.CustomEvent._allActiveEvents.delete(this)
  }
  static unsubscribeFromAllByOwner(owner) {
    for (let event of util.CustomEvent._allActiveEvents)
      event.unsubscribeByOwner(owner)
  }
  onNextTrigger(owner=null, opt) {
    return this.onNextTriggerWhere(()=>true, owner, opt)
  }
  // skip allows you to skip a number of triggers before the promise resolve.
  onNextTriggerWhere(condition, owner=null, {skip=0}={}) {
    return new Promise(resolve => {
      let wrapper = (...x) => {
        if (condition(...x)) {
          if (skip)
            skip -= 1
          else {
            this.unsubscribe(wrapper)
            resolve(...x)
          }
        }
      }
      this.subscribe(wrapper, owner)
    })
  }
  trigger = stateChange => {
    this.eventState = {...this.eventState, ...stateChange}
    for (let obs of this._observers)
      obs.observer(this.eventState)
  }
}

// Provides a lockProps and unlockProps to a component. When lockProps() is called,
// the props will not update until unlockProps() is called.
util.providePropLocker = Component => {
  return class PropLockerProvider extends React.PureComponent {
    lastProps = {}
    state = {lockedProps: null}
    provided = {
      lockProps: () => {if (this.state.lockedProps==null) this.setState({lockedProps: this.lastProps})},
      unlockProps: () => this.setState({lockedProps: null}),
    }
    render() {
      this.lastProps = this.props
      return (
        <Component {...this.provided} {...(this.state.lockedProps||this.props)}/>
      )
    }
  }
}

// Calls it's properties onMount and onUnmount when appropriate.
util.MountObserver = class extends React.PureComponent {
  componentDidMount() {
    if (this.props.didMount)
      this.props.didMount()
  }
  componentWillMount() {
    if (this.props.willMount)
      this.props.willMount()
  }
  componentWillUnmount() {
    if (this.props.willUnmount)
      this.props.willUnmount()
  }
  render = () => this.props.children
}

// Useful to make elements fade in/out (or other similar transitions).
// DEPRECATED: Use the transition-group library instead.
// Properties: (* items are required)
//   *show: if true, the element will start fading in, otherwise it'll start fading out.
//   trigger: Can be given in place of the show property. This should be a function that
//       will be called with a special function as an argument. When this special function
//       is called with an activeDelayTimeout argument, this will transition into
//       the shown phase, stay there for the given activeDelayTimeout time,
//       then transition back out. If no argument is given, then it'll transition out as quick as possible.
//       e.g. <Transition trigger={f => this.triggerTransition = f}> ... this.triggerTransition(100)
//   *timeout: After this many miliseconds the fade animation will be complete. Usually this timeout
//       will be the same as whatever the css is using for it's transition time.
//   *classPrefix: The css prefix which will be used to set classNames to the child element.
//       These are the possible classes with a prefix example.
//       example-init: initialized the transition animation
//       example-shown: Comes soon after example-init, should transition the component to it's normal shown state.
//       example-hiding: triggers as soon as the component starts hiding.
//       example-hidden: After the given timeout the class will be set to this.
//   onHidingPhase: called when the hiding phase starts. If false is returned, the
//       hiding phase will be skipped, skipping the animation.
//   children: If the child is a react component, it will recieve additional classes
//       depending on the classPrefix provided. It can also be a function which
//       returns a react component. This function will recieve the current phase
//       as a non-prefixed string, and will still modify the returned component
//       and give it its additional class.
util.Transition = class extends React.PureComponent {

  state = {
    phase: 'hidden'
  }
  hideTimeout = null
  constructor(props) {
    super(props)
    if (props.trigger)
      props.trigger(this.trigger)
  }

  componentDidMount() {
    this.updateStateWithProps(this.props)
  }
  componentWillReceiveProps(props) {
    this.updateStateWithProps(props)
  }
  updateStateWithProps(props) {
    if (props.show===true && (this.state.phase==='hidden'||this.state.phase==='hiding')) {
      this.transitionIn()
    } else if (props.show===false && (this.state.phase==='init'||this.state.phase==='shown')) {
      this.transitionOut()
    }
  }

  render() {
    let child = this.props.children
    if (typeof child === 'function')
      child = child(this.state.phase)
    if (child == null)
      return null
    return React.cloneElement(child, {
      className: (child.props.className||'') + ` ${this.props.classPrefix}-` + this.state.phase
    })
  }

  transitionIn(callback=()=>{}) {
    clearTimeout(this.hideTimeout)
    this.setState({phase: 'init'})
    window.requestAnimationFrame(() => {
      this.setState({phase: 'shown'})
      callback()
    })
  }

  transitionOut() {
    if (this.props.onHidingPhase && this.props.onHidingPhase()===false) {
      this.setState({phase: 'hidden'})
      return
    }
    this.setState({phase: 'hiding'})
    this.hideTimeout = setTimeout(() => {
      this.setState({phase: 'hidden'})
    }, this.props.timeout)
  }

  trigger = (activeDelayTimeout=0) => {
    this.transitionIn(() => {
      setTimeout(() => {
        this.transitionOut()
      }, activeDelayTimeout)
    })
  }
}

// Code from https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript#answer-2880929
// This exports a dictionary containing the query args of the url.
util.queryParams = {}
let updateQueryParams = () => {
  let match
  let pl = /\+/g // Regex for replacing addition symbol with a space
  let removeScriptTags = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
  let search = /([^&=]+)=?([^&]*)/g
  let decode = s => decodeURIComponent(s.replace(pl, ' '))
  let query  = window.location.search.substring(1)

  util.queryParams = {};
  while (true) {
    match = search.exec(query)
    if (!match) break
    util.queryParams[decode(match[1])] = decode(match[2]).replace(removeScriptTags, '')
  }
}
updateQueryParams()
window.addEventListener('popState', updateQueryParams)

util.getQueryParam = (param, {parse=v=>v, fallback=undefined}={}) => {
  if (param in util.queryParams)
    return parse(util.queryParams[param])
  return fallback
}

{
  let preloadImages = new Set()
  let preloadNowImages = new Set()
  let fetchNewImages = ()=>{}
  document.addEventListener('DOMContentLoaded', ()=>{
    let preloadContainer = document.createElement('div')
    preloadContainer.style.display = 'none'
    document.body.appendChild(preloadContainer)
    fetchNewImages = () => {
      ReactDOM.render([
        ...[...preloadNowImages].map(src => <img key={'img:'+src} src={src} alt=''/>),
        ...[...preloadImages].map(src => <link key={'link:'+src} rel='prefetch' href={src}/>),
      ], preloadContainer)
    }
    fetchNewImages()
  })
  // Preloads the list of urls when the browser is idle, or if now=true, as soon as the dom is ready.
  // Use this function for images that the user will likely need later, and you want them to preload
  // when the browser's not busy. Or, in some cases you can use this to tell the browser of important images
  // before the browser can discover them. In this second case you should set now to true to make it more urgent.
  util.preloadImages = (images, {now=false}={}) => {
    if (now)
      preloadNowImages = new Set([...preloadNowImages, ...images])
    else
      preloadImages = new Set([...preloadImages, ...images])
    fetchNewImages()
  }
}

// Doesn't load the image until the page loads.
util.LazyImg = class extends React.PureComponent {
  state={windowLoaded: false}
  constructor(props) {
    super(props)
    if (document.readyState==='complete')
      this.state.windowLoaded = true
    else
      window.addEventListener('load', this._onLoad)
  }
  componentWillUnmount() {
    window.removeEventListener('load', this._onLoad)
  }
  _onLoad = () => this.setState({windowLoaded: true})
  render() {
    return <img {...this.props} src={this.state.windowLoaded ? this.props.src : ''} alt={this.props.alt}/>
  }
}
