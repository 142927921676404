import util from 'src/util/util'
import globals from 'src/util/globals'
import {setNonPersistant, booleanParser} from './tools'
import {State} from 'src/util/stateManager'

export default globals.homeState = {
  // True after the Page component is mounted (and thus all children are also mounted)
  pageMounted: new State(false),
  // True once fonts have loaded or timed out.
  fontsReady: new State(false),
  // Which slide the testimonials are showing.
  testimonialSlide: setNonPersistant(new State(util.getQueryParam('testimonial-slide', {parse:Number, fallback:0}))),
  // True if a testimonial video is currently playing
  playingVideo: setNonPersistant(new State(util.getQueryParam('playing-video', {parse:booleanParser, fallback:false}))),
}

let fontsReady = () => {
  if (!globals.homeState.fontsReady.get())
    globals.homeState.fontsReady.set(true)
}
if (document.fonts && document.fonts.ready) {
  document.fonts.forEach(f => {
    if (f.family==='Lato') {
      f.load() // This is for Firefox. It doesn't load the font by default, probably because it isn't visible.
      f.loaded.then(fontsReady)
    }
  })
  setTimeout(fontsReady, 500)
} else {
  fontsReady()
}
