/* The header to the sub pages

Properties: (* items items optional)
  mainColor: Background color of the header.
  transitionHeader: Is this header not actually at the top of the page, but part of a page-transition effect?
  flush: Removes the shadow
  extendBackgroundAbove: Make colored background go above visible region. Useful for the scroll-past-top-of-page effect.
  location: You can have the navigation render as it would for a different page with this.
  headerChild: Child component injected into the header.
*/

import React from 'react'
import {Route} from 'react-router-dom'
import Navigation from './Navigation'
import 'src/css/App/Header.css'

class Page extends React.PureComponent {

  render() {
    let color = this.props.mainColor || 'rgba(0,0,0,0)'
    if (this.props.transitionHeader)
      color = null
    return (
      <Route>{({location}) => (
        <header className={'Header'
        +(this.props.transitionHeader?' transition-header':'')
        +(this.props.flush?' flush':'')}>
          <div className={'background'+(this.props.extendBackgroundAbove?' extend-background':'')}
          style={{
            background: color,
            transition: location.state&&location.state.transition==='scrolled'?'':'background 300ms',
          }}/>
          <div className='content'>
            <Navigation location={this.props.location}/>
            <div className='child-container'>
              {this.props.headerChild}
            </div>
          </div>
        </header>
      )}</Route>
    )
  }
}

export default Page
