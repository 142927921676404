/* This is the page header with navigation links

Properties: (All items optional)
  fixed: Is the navigation always visible?
  visible: Only can be used if fixed is true, defaults to true. Desides if the navigation is visible or hidden.
  location: You can have the navigation render as it would for a different page with this.
*/

import React from 'react'
import {Link, NavLink, Route} from 'react-router-dom'
import {pageWillLoad} from 'src/shared/pageWrapper'
import util from 'src/util/util'
import 'src/css/App/Navigation.css'
const {CustomEvent} = util

export default class Navigation extends React.Component { // TODO: Component (not pure) is needed to make Route work properly

  state = {
    forceVisible: false,
  }

  componentWillMount() {
    pageWillLoad.subscribe(() => {
      // If you click on a nav-link, then scroll to another page, then the nav bar looks
      // weird because one item is focused and another is active, and they have the same look.
      // We'll just unfocus all links during page changes to fix this.
      if (this.$el)
        for (let $link of this.$el.querySelectorAll('a'))
          $link.blur()
    }, this)
  }
  componentWillUnmount() {
    CustomEvent.unsubscribeFromAllByOwner(this)
  }

  render() {
    let visible = this.state.forceVisible || (this.props.visible!=null ? this.props.visible : true)
    let classes = 'Navigation'
    if (!this.props.fixed || visible)
      classes += ' show'
    if (this.props.fixed)
      classes += ' fixed'
    let location = (this.props.location
      ? {pathname:this.props.location} // Mocking a react-router location object
      : null
    )

    return (
      <Route path='/feature' location={location}>{({match}) => (
        <nav className={classes} ref={el=>this.$el=el}
        onFocus={()=>this.setState({forceVisible:true})} onBlur={()=>this.setState({forceVisible:false})}>
          <Route path='/' exact location={location}>{({match:homeMatch}) => (
            <NavLink className='main-link' to='/' exact tabIndex={homeMatch?-1:0}>Home</NavLink>
          )}</Route>

          <span className={'link-group'+(match?' active':'')}>
            <Link className={'main-link'+(match?' active':'')}
            to={match?{}:'/feature/support'}> {/* When to={} the link goes nowhere */}
              Features
            </Link>
            {match && <React.Fragment>
              <NavLink className='sub-link' to='/feature/support'>Support</NavLink>
              <NavLink className='sub-link' to='/feature/performance'>Performance</NavLink>
              <NavLink className='sub-link' to='/feature/security'>Security</NavLink>
            </React.Fragment>}
          </span>

          <NavLink className='main-link' to='/plans' exact>Pricing</NavLink>
          <a className='main-link' href='https://tickets.wp-overwatch.com'>Help Center</a>
        </nav>
      )}</Route>
    )
  }
}
