/* Shows a loading bar. This placeholder is used while waiting for a page to load.
The exception is when transitioning to a page above you - the styling of it causes this to be masked.
(which isn't neccesarily a feature, its just how its currently implemented)

Properties: (All items optional)
  onLightBackground: Changes the loading color to look better on a light background.
*/

import React from 'react'
import 'src/css/shared/PagePlaceholder.css'

export default function PagePlaceholder({onLightBackground}) {
  if (document.readyState!=='complete')
    return null // We only want to show the animation during page changes, not initial load.
  return <div className={'PagePlaceholder'+(onLightBackground?' on-light-background':'')}/>
}
