// Some utilities to help with the state modules.

import {pageWillLoad} from '../pageWrapper'

// Use this function to make a particular state not stay persistant when the page changes.
// Be careful, only state with an initial value that is JSON.parse() compatable should be used.
export const setNonPersistant = state => {
  state.allowResets()
  pageWillLoad.onNextTrigger(() => { // We ignore the initial page load.
    pageWillLoad.subscribe(()=>state.reset())
  })
  return state
}

export const booleanParser = value => value === 'true'
export const commaSeperatedList = value => value.split(',')
