import Loadable from 'react-loadable'
import pageWrapper from 'src/shared/pageWrapper'
import PagePlaceholder from 'src/shared/PagePlaceholder.js'

const pageLoader = () => import('./components/Page.js')
export default pageWrapper({
  loader: pageLoader,
  loading: PagePlaceholder,
})

const headerLoader = () => import('./HeaderBackground')
export const PlansPageHeaderBackground = Loadable({
  loader: headerLoader,
  loading: () => null,
})

export const preload = () => {pageLoader(); headerLoader()}
export {PRESETS, PRESET_PRICES, PRESET_IDS, MIN_PRICE, DISCOUNTS, SLIDER_ID_TO_NAME, SLIDER_PRICES} from './constants'
