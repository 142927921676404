import React from 'react'
import ReactDOM from 'react-dom'
import ObjectValuesShim from 'object.values' // shim for chrome on ios
import 'intersection-observer' // Shim for iphone
import App from './App'
import './css/index.css'

if (!Object.values)
  Object.values = ObjectValuesShim

let isIE = /MSIE 9/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)

if (!isIE)
  ReactDOM.render(<App/>, document.getElementById('root'))
else
  ReactDOM.render((
    <p style={{margin: '0 auto', width: '40vw', padding: 50, color: 'white', background: '#900',
    fontSize: '1.6rem', borderRadius: 20, position: 'relative', top: 200}}>
      Sorry, Internet explorer is not supported :(. It's actually harder than you would think to make sites work in Internet Explorer.
      <br/><br/>Try using any other browser like Chrome, Firefox, or Edge.
    </p>
  ), document.getElementById('root'))
