// All of these functions are meant to be used in the dev tools.

import globals from 'src/util/globals'
import homeState from './homeState'
import plansState from './plansState'
import {DISCOUNTS} from 'src/pages/Plans'

globals.generateURL = () => {
  let parts = {}

  let testimonialSlide = homeState.testimonialSlide.get()
  if (testimonialSlide) parts['testimonial-slide'] = testimonialSlide

  let playingVideo = homeState.playingVideo.get()
  if (playingVideo) parts['playing-video'] = playingVideo

  let emailBoxContent = plansState.emailBoxContent.get()
  if (emailBoxContent) parts['email-box-content'] = emailBoxContent

  let activeDiscounts = plansState.discounts.activeDiscounts.get()
  if (!activeDiscounts.includes('unlucky')) // If true then no other discounts are set.
    parts['active-discounts'] = activeDiscounts.join(',')

  let sliderValues = plansState.sliders.values.get()
  if (Object.values(sliderValues).filter(x=>!!x).length!==0) // If any of the values are anything but 0 or false
    parts['slider-values'] = JSON.stringify(sliderValues)

  let activePresetID = plansState.sliders.activePresetID.get()
  if (activePresetID!=null) parts['active-preset-id'] = activePresetID

  let showHiddenDiscounts = plansState.discounts.showHiddenDiscounts.get()
  if (showHiddenDiscounts) parts['hidden-discounts'] = 'true'

  let visibleSpecialDiscounts = plansState.discounts.visibleSpecialDiscounts.get()
  if (visibleSpecialDiscounts.length>0) parts['special-discounts'] = visibleSpecialDiscounts.join(',')

  let joinedParts = []
  for (let [key,value] of Object.entries(parts))
    joinedParts.push(`${key}=${encodeURIComponent(value)}`)
  let url = window.location.href.split('#')[0].split('?')[0]+'?' + joinedParts.join('&')
  if (window.location.href.includes('#'))
    url += '#'+window.location.href.split('#')[1]
  console.info('Use the following url to keep most of the state the same on the site: ', url)
}
globals.generateURL.help = () => {
  console.info('Generates a url that can be given to others, that preserves much of the state of the website.')
  console.warn('NOTE: There is no garentee that this url generation function will stay updated, it\'s easy to update the code and forget to update this generation function with it.')
  console.info('Available query params: testimonial-slide, playing-video, email-box-content, active-discounts, slider-values, active-preset-id, hidden-discounts, special-discounts')
}

globals.toggleHiddenDiscounts = () => {
  plansState.discounts.showHiddenDiscounts.set(!plansState.discounts.showHiddenDiscounts.get())
}
globals.toggleHiddenDiscounts.help = () => console.info('Toggles weather or not the hidden discount panel is visible.')

globals.toggleSpecialDiscounts = items => {
  if (!items)
    plansState.discounts.visibleSpecialDiscounts.set([])
  else
    plansState.discounts.visibleSpecialDiscounts.update(specialDiscounts=>{
      for (let item of items) {
        if (specialDiscounts.includes(item))
          specialDiscounts.splice(specialDiscounts.indexOf(item), 1)
        else
          specialDiscounts.push(item)
      }
    })
}
globals.toggleSpecialDiscounts.help = () => {
  console.info(`\
Pass in an array of strings to choose which special discounts to toggle, or nothing to disable them all.
Possible array values: ${Object.keys(DISCOUNTS.special).join(', ')}`)
}
