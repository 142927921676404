import server from 'src/util/server'
import {state} from 'src/shared/logic'

export let events = {
  // This should be called when the user requests to submit the comment form in the footer.
  onSubmitCommentForm: (email, message) => {
    server.createTicket({
      source: 'PUBLIC:OW_SITE_CONTACT_US',
      title: 'Someone contacted us on the website',
      message, email,
    }, state.commentSubmissionStatus)
  },
}
