// functions to communicate with the server

import React from 'react'
import {config} from './globals'

let server = {}
export default server

// Sends a ticket to the ticketing service
// args:
//   statusState: A State object from stateManager.js that the function will update.
//     It should have the value of an object with the following keys:
//     isSubmitted (bool), hasResponded (bool), submissionError (jsx object or false)
//   source: A source ID indicating to the ticket system which form this submission came from.
//   message: What will be put in the body of the brodcast (plain text).
//   email: The email of the user submitting the ticket
server.createTicket = async ({title, message, source, email}, statusState) => {
  let onSuccess = () => {
    statusState.update(status => status.hasResponded = true)
  }
  let onError = errMessage => {
    statusState.update(status => Object.assign(status, {isSubmitted: false, submissionError: errMessage}))
  }
  let submissionErrorMessage = (<span>
    Failed to submit, monkeys must have gotten into the server room again.<br/>Try again later, or shoot us an email.
  </span>)

  statusState.update(status => Object.assign(status, {isSubmitted: true, submissionError: false}))

  let res
  try {
    const ticketsService = (await serviceRegistryFetch())['Tickets']
    res = await fetch(ticketsService+'/v1/ticket', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: new URLSearchParams({
        title, source, email,
        message: '<pre>' + escapeHtml(message) + '</pre>',
        category: 'low priority',
      }).toString(),
    })
  } catch (err) {
    onError(submissionErrorMessage)
    throw err
  }

  if (res.status>=300) {
    onError(submissionErrorMessage)
    console.error(res.status+' error')
  } else
    onSuccess()
}

const serviceRegistryFetch = async () => {
  let res = await fetch(config.serviceRegistry + '/v1/services.json')
  if (!res.ok)
    throw new Error('Service Registry returned a bad status code.')
  return await res.json()
}

// Escapes special characters, turning characters like & into &amp;
function escapeHtml(html) {
  let text = document.createTextNode(html)
  let p = document.createElement('p')
  p.appendChild(text)
  return p.innerHTML
}