// General utilities to help manage state across multiple components

// Provides an easy way for multiple components to share a piece of state.
// The component can call use(listener) to retrieve the value of the state, and
// to subscribe to state changes.
//
// Usually the listener would be element.forceUpdate, however forceUpdate isn't a bound
// function, so you would have to bind it. You could add the following line in your class
// and use it in your render function every time you wish to use use():
// (It's best to call stateTools.gotUpdated inside render to let it apply some speed optimizations)
//     stateListener = stateTools.useAsListener(()=>this.forceUpdate())
export class State {
  constructor(initialValue=null) {
    this._listeners = []
    this._value = initialValue
    this._allowResets = false
    this._initialState = null
    stateInstances.push(this)
  }

  // Sets the value and notifies the listening components of the change
  set(value) {
    this._value = value
    triggerListenersWhenReady(this._listeners)
  }
  // Alternative form to modify the state. The callback will be passed the current
  // state value, which can be modified in place. When the callback returns
  // all the listeners will be notified that it was changed.
  update(callback) {
    callback(this._value)
    triggerListenersWhenReady(this._listeners)
  }

  get = () => this._value

  // Returns the value and subscribes the listener to changes in the value.
  use(listener) {
    if (!this._listeners.includes(listener))
      this._listeners.push(listener)
    return this.get()
  }
  subscribe = (...a) => {this.use(...a)}

  // Resets the state back to its default form.
  reset() {
    if (this._allowResets===false)
      throw new Error('You must call allowResets() first.')
    this._value = JSON.parse(this._initialState)
  }
  // Allows use of the reset() function. Must be called before the state gets modified.
  // Be careful, this can only be called if the initial value was JSON.parse compatable.
  allowResets() {
    this._allowResets = true
    // JSON functions are used to clone the state
    this._initialState = JSON.stringify(this._value)
  }

  _removeListeners(listener) {
    removeFromArray(this._listeners, listener)
  }
}

// This is a special case of the State class. This state piece is built from
// joining together existing pieces of state.
// It's pretty much a way of giving existing state in a different form
// Example usage:
//     // This retrieves two values from the state and adds them.
//     // It can be subscribed to through .use() just like the normal State class.
//     // If someValue or otherValue ever changes, the function passed in will be called
//     // again to find the new value of the StateInterpreter, and any function subscribe
//     // to the StateInterpreter will also be notified.
//     new StateInterpreter(listener => {
//       return state.someValue.use(listener) + state.otherValue.use(listener)
//     })
export class StateInterpreter {
  constructor(interpreter) {
    this._interpreter = interpreter
    // Any time any of that state gets updated, _callListeners should be called,
    // and we can know and re-evaluate the value of this component.
    this._value = null
    this._isValueInSync = false
    this._listeners = []
    stateInstances.push(this)
  }

  // Retrieves the value.
  // The value will be reevaluated if needed.
  get() {
    if (!this._isValueInSync) {
      this._value = this._interpreter(this._callListeners)
      this._isValueInSync = true
    }
    return this._value
  }

  use(listener) {
    if (!this._listeners.includes(listener)) {
      this._listeners.push(listener)
    }
    return this.get()
  }

  // A function unique to each instance of StateInterpreter that gets passed
  // into the interpreter functions. This will trigger all the listeners.
  _callListeners = () => {
    this._isValueInSync = false
    triggerListenersWhenReady(this._listeners)
  }

  _removeListeners(listener) {
    removeFromArray(this._listeners, listener)
  }
}

let stateInstances = []

export let stateTools = {
  // Adds some speed optomizations to state listeners, specifically for when
  // the listener is component.forceUpdate. Should be called inside render().
  gotUpdated: listener => listenerGotCalled(listener),

  // Removes the listener from anywhere it might be being used to listen for state updates.
  // Should be called with componentWillUnmount(), if not, its a bit of a memory leak
  // when the component does unmount, and errors could happen if the listener is ever triggered.
  removeListeners: listener => {
    for (let instance of stateInstances)
      instance._removeListeners(listener)
    removeFromArray(pendingListenerTriggers, listener)
  }
}

function removeFromArray(array, item) {
  let index = array.indexOf(item)
  if (index!==-1)
    array.splice(index, 1)
}

// The setup below will allow lots of code to queue up listeners that need to be updated.
// Once the javascript call stack is cleared (the timeout of 0 seconds) all the listeners will
// be called at once. This prevents unenesarry repeated calls of the same listener.

// Listeners that will be called as soon as the timeout happens
let pendingListenerTriggers = []
// Listeners that are currently being iterated over and triggered.
let listenerTriggersBeingTriggered = [] // Note: It's ok for this list to contain null.
// If a timeout of 0 seconds is set. This timeout lets javascript finish what it's doing and it
// prevents duplicated calls to the same listeners if the same listener is asked to be called multiple
// times in quick sussession.
let isListenerTriggerTimeoutSet = false
function triggerListenersWhenReady(listeners) {
  for (let listener of listeners)
    if (!pendingListenerTriggers.includes(listener))
      pendingListenerTriggers.push(listener)

  if (!isListenerTriggerTimeoutSet) {
    isListenerTriggerTimeoutSet = true
    // Due to a bug in chrome, setTimeout(..., 0) doesn't work here. So we're using requestAnimationFrame instead.
    // see https://bugs.chromium.org/p/chromium/issues/detail?id=813665
    requestAnimationFrame(triggerListenersNow)
  }
}
function triggerListenersNow() {

  // Things like StateInterpreter might provide a listener that alters state and
  // triggers more listeners. We have to make sure those newly added listeners aren't
  // ignored because they're already in pendingListenerTriggers, because their
  // new state could be useful to the listeners. Therefore we reset the following variables
  // before calling the listeners.
  listenerTriggersBeingTriggered = pendingListenerTriggers
  pendingListenerTriggers = []
  isListenerTriggerTimeoutSet = false

  for (let listener of listenerTriggersBeingTriggered) {
    if (listener)
      listener()
  }
}
// This should be called whenever a state listener gets called (mostly if that listener is component.forceUpdate).
// If a parent's forceUpdate gets called, react will automatically update all of it's children. If one of the children
// was listening for changes also, then it would be updated a second time by us manually triggering its forceUpdate.
// This is not desireable, so whenever a listener gets called, this function gets called, which then removes itself
// from the pending list of things to be updated.
// Because parent components get created before their children, their listeners should be registered before their children,
// therefor it should never happen that we update a child, then a parent which in turn updates the child a again. (if it does happen, oh well)
function listenerGotCalled(listener) {
  if (!listenerTriggersBeingTriggered.length)
    return

  let index = listenerTriggersBeingTriggered.indexOf(listener)
  if (index===-1)
    return

  // We set it to null instead of deleting it so that we don't mess up the for loop that's currently happening in triggerListenersNow()
  listenerTriggersBeingTriggered[index] = null
}
