// These are all variables that are also used in _vars.scss.
// If you wish to change one of these, modify _vars.scss also.

export const TABLET_SCREEN_SIZE = 1000
export const PHONE_SCREEN_SIZE = 640

export const DOTS_WIDTH = 13

export const MOBILE_OVERLAY_HEIGHT = .35

export const MOBILE_DARK_BACKGROUND = '#1a1a1a'

export const GENERAL_ANIMATION_TIME = 300
export const SLIDER_FLASH_ANIMATION_TIME = 300
export const VIDEO_FADE_ANIMATION_TIME = 300
export const VIDEO_FADE_ANIMATION_DELAY = 300

export const AUTO_ADD_UNLUCKY_DISCOUNT = false // better ux for newcomers, but not for existing clients.
export const DISABLE_SUPPORT = false
