/* Minimal alternative to ArticleFrame that only provides basic features. */
import React from 'react'

export default function MinimalFrame({color, children}) {
  return (
    <React.Fragment>
      <meta name='theme-color' content={color}/>
      {children}
    </React.Fragment>
  )
}
