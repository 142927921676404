import util from 'src/util/util'
import globals from 'src/util/globals'
import {setNonPersistant, booleanParser, commaSeperatedList} from './tools'
import {MIN_PRICE, DISCOUNTS} from 'src/pages/Plans'
import {State, StateInterpreter} from 'src/util/stateManager'
import {AUTO_ADD_UNLUCKY_DISCOUNT} from 'src/sharedConstants'

let showHiddenDiscounts = new State()
showHiddenDiscounts.use(value => {
  if (showHiddenDiscounts.get())
    localStorage.showHiddenDiscounts = showHiddenDiscounts.get()
  else
    delete localStorage.showHiddenDiscounts
})
showHiddenDiscounts.set(
  localStorage.showHiddenDiscounts ||
  util.getQueryParam('hidden-discounts', {parse:booleanParser, fallback:false})
)

let visibleSpecialDiscounts = new State()
visibleSpecialDiscounts.subscribe(value => {
  if (visibleSpecialDiscounts.get().length)
    localStorage.visibleSpecialDiscounts = visibleSpecialDiscounts.get().join(',')
  else
    delete localStorage.visibleSpecialDiscounts
})
visibleSpecialDiscounts.set([...new Set([
  ...(localStorage.visibleSpecialDiscounts ? localStorage.visibleSpecialDiscounts.split(',') : []),
  ...util.getQueryParam('special-discounts', {parse:commaSeperatedList, fallback:[]}), // Using special-discounts query arg is prefered over special-discount
  ...util.getQueryParam('special-discount', {parse:commaSeperatedList, fallback:[]}),
])])

let state
export default state = globals.plansState = {
  // Form info
  emailBoxContent: new State(util.getQueryParam('email-box-content', {fallback:''})),
  sliderFormSubmissionStatus: setNonPersistant(new State({
    isSubmitted: false,
    hasResponded: false,
    submissionError: false,
  })),

  discounts: {
    // Keys are discount ids. Values are boolean indicating if they're checked or not.
    activeDiscounts: new State(util.getQueryParam('active-discounts', {parse:commaSeperatedList, fallback:(AUTO_ADD_UNLUCKY_DISCOUNT?['unlucky']:[]) })),
    _afterDiscountInfo: new StateInterpreter(priceAfterDiscountStateInterpreter),
    // Be aware that these don't return rounded decimals. You probably want to use .toFixed(2) before displaying them to the user.
    firstYearDiscountPrice: new StateInterpreter(updater => state.discounts._afterDiscountInfo.use(updater).firstYearDiscountPrice),
    afterFirstYearDiscountPrice: new StateInterpreter(updater => state.discounts._afterDiscountInfo.use(updater).afterFirstYearDiscountPrice),
    // True if the minimum price for plans was hit.
    hitLowestFirstYearDiscountPrice: new StateInterpreter(updater => state.discounts._afterDiscountInfo.use(updater).hitLowestFirstYearPrice),
    // This is true if the hidden discounts should be shown.
    showHiddenDiscounts,
    // This is a list of special discount names that should be shown.
    visibleSpecialDiscounts,
  },

  sliders: {
    // The value of each slider.
    values: new State(util.getQueryParam('slider-values', {parse:JSON.parse, fallback:{}})),
    // The id of the currently active preset.
    activePresetID: new State(util.getQueryParam('active-preset-id', {parse:Number, fallback:null})),
    // Each time this changes a recheck will happen on weather or not the sliders are in an errored state.
    doIncompatabilityCheck: new State(true),
    // A dictionary of stuff that will be inside the slider details area.
    details: setNonPersistant(new State({
      show: false,
      sliderID: null,
      color: null,
      title: null,
      content: null,
    })),
    // The currently selected prices of all the sliders.
    prices: new State({}),
    totalPriceBeforeDiscounts: new StateInterpreter(updater => {
      return Object.values(state.sliders.prices.use(updater)).reduce((a,b)=>{
          return b ? a+b : a||0
      }, 0) // Sums the prices
    }),
  },
}

// Processes the discounts to figure out the final price, along with other useful info.
function priceAfterDiscountStateInterpreter(updater) {
  let totalPrice = state.sliders.totalPriceBeforeDiscounts.use(updater)
  let activeDiscounts = state.discounts.activeDiscounts.use(updater)

  let firstYearDiscountPrice = totalPrice
  let afterFirstYearDiscountPrice = totalPrice
  // calculate the new price
  for (let discountType of ['normal', 'hidden', 'special']) {
    for (let discountID of Object.keys(DISCOUNTS[discountType])) {
      if (!activeDiscounts.includes(discountID))
        continue
      let {priceDiscount=0, percentDiscount=0, onlyFirstYear=false} = DISCOUNTS[discountType][discountID]
      let priceCut = totalPrice*(percentDiscount/100) + priceDiscount

      firstYearDiscountPrice -= priceCut
      if (!onlyFirstYear)
        afterFirstYearDiscountPrice -= priceCut
    }
    totalPrice = firstYearDiscountPrice
  }

  // Apply minimum price
  let hitLowestFirstYearPrice = false
  if (firstYearDiscountPrice < MIN_PRICE) {
    firstYearDiscountPrice = MIN_PRICE
    hitLowestFirstYearPrice = true
  }
  if (afterFirstYearDiscountPrice < MIN_PRICE)
    afterFirstYearDiscountPrice = MIN_PRICE

  return {firstYearDiscountPrice, afterFirstYearDiscountPrice, hitLowestFirstYearPrice}
}
