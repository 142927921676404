import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'

import ArticleFrame from './ArticleFrame'
import MinimalFrame from './MinimalFrame'
import {MOBILE_DARK_BACKGROUND} from 'src/sharedConstants'

import HomePage from 'src/pages/Home'
import PlansPage, {preload as preloadPlansPage, PlansPageHeaderBackground} from 'src/pages/Plans'
import SupportPage, {preload as preloadSupportPage} from 'src/pages/Support'
import PerformancePage, {preload as preloadPerformancePage} from 'src/pages/Performance'
import SecurityPage, {preload as preloadSecurityPage} from 'src/pages/Security'
import ErrorPage from 'src/pages/Error'

const pagesInfo = {
  support: {
    target: '/feature/support',
    title: 'Support',
    color: '#1d4b37',
    preload: preloadSupportPage,
  },
  performance: {
    target: '/feature/performance',
    title: 'Performance',
    color: '#bb9424',
    preload: preloadPerformancePage,
  },
  security: {
    target: '/feature/security',
    title: 'Security',
    color: '#31435e',
    preload: preloadSecurityPage,
  },
  plans: {
    target: '/plans',
    title: 'Plans',
    color: '#800',
    backgroundColor: 'white',
    mobileBackgroundColor: MOBILE_DARK_BACKGROUND,
    preload: preloadPlansPage,
  }
}

export default function PageRouter() {return (
  <BrowserRouter>
    <Switch>
      <Route path='/' exact render={() => (
        <MinimalFrame color='#800'>
          <HomePage/>
        </MinimalFrame>
      )}/>

      <Route path={pagesInfo.support.target} exact render={() => (
        <ArticleFrame {...pagesInfo.support} pageBelow={pagesInfo.performance}>
          <SupportPage/>
        </ArticleFrame>
      )}/>
      <Route path={pagesInfo.performance.target} exact render={() => (
        <ArticleFrame {...pagesInfo.performance}
        pageAbove={pagesInfo.support} pageBelow={pagesInfo.security}>
          <PerformancePage/>
        </ArticleFrame>
      )}/>
      <Route path={pagesInfo.security.target} exact render={() => (
        <ArticleFrame {...pagesInfo.security} pageAbove={pagesInfo.performance}
        pageBelow={pagesInfo.plans}>
          <SecurityPage/>
        </ArticleFrame>
      )}/>

      <Route path={pagesInfo.plans.target} exact render={() => (
        <ArticleFrame {...pagesInfo.plans} pageAbove={pagesInfo.security}
        headerChild={<PlansPageHeaderBackground/>}>
          <PlansPage/>
        </ArticleFrame>
      )}/>

      <Route path='/' render={() => (
        <ArticleFrame flush>
          <ErrorPage code={404} message='The page you are looking for can not be found.'/>
        </ArticleFrame>
      )}/>
    </Switch>
  </BrowserRouter>
)}
