// This file contains constants that pertain to this page but may be useful outside of it also.

export const SLIDER_COLORS = {
  slider1: 'rgba(20,30,255, 0.6)',
  slider2: 'rgba(170,155,0, 0.6)',
  slider3: 'rgba(0,70,10, 0.6)',
  slider4: null,
  slider5: 'rgba(220,10,0, 0.6)',
}
export const SLIDER_PRICES = {
  security: {nonePrice:10, highPrice:15},
  speed: {nonePrice:0, mediumPrice:10, highPrice:35},
  support: {nonePrice:0, mediumPrice:95, highPrice:189},
  updates: {nonePrice:0, highPrice:70},
  ecommerce: 5.50,
}
export const SLIDER_NAME_TO_ID = {
  security: 1, speed: 2, support: 3, updates: 4, ecommerce: 5,
}
export const SLIDER_ID_TO_NAME = Object.assign({}, // Flip key/values
  ...Object.entries(SLIDER_NAME_TO_ID).map(([k,v]) => ({[v]:k}))
)

export const ECOMMERCE_PRESET = {1:2, 2:2, 3:2, 4:0}
export const HIGH_PERFORMANCE_PRESET = {1:2, 2:2, 3:1, 4:0}

export const PRESET_IDS = {ecommerce:0, highPerformance:1}
export const PRESETS = {
  [PRESET_IDS.ecommerce]: ECOMMERCE_PRESET,
  [PRESET_IDS.highPerformance]: HIGH_PERFORMANCE_PRESET,
}

export const MIN_PRICE = 10

const calcPrice = preset => {
  let total = 0
  for (let name of ['security','speed','support','updates']) {
    let sliderValue = preset[SLIDER_NAME_TO_ID[name]]
    let key = ['nonePrice','mediumPrice','highPrice'][sliderValue]
    total += SLIDER_PRICES[name][key]
  }

  let sliderValue = preset[SLIDER_NAME_TO_ID['ecommerce']]
  total += sliderValue===1 ? SLIDER_PRICES['ecommerce'] : 0
  return total
}
export const PRESET_PRICES = {
  ecommerce: calcPrice(ECOMMERCE_PRESET),
  highPerformance: calcPrice(HIGH_PERFORMANCE_PRESET),
}

export const DISCOUNTS = {
  normal: {
    lowTraffic: {percentDiscount:20},
    lowSelling: {percentDiscount:10},
    smallSite: {priceDiscount:10},
    unlucky: {percentDiscount:7, onlyFirstYear:true},
  },
  hidden: {
    charity: {priceDiscount:10},
    multiSite: {percentDiscount:30},
    startup: {percentDiscount:20, onlyFirstYear:true},
    noMoneyHandling: {priceDiscount:10},
  },
  special: {
    agency: {percentDiscount:10},
    sitesmash: {percentDiscount:30},
    'kids-who-count': {percentDiscount:50},
    'esa-utah': {percentDiscount:50},
    'ali-sumsion': {priceDiscount:35, onlyFirstYear:true},
    'c21bushnell': {percentDiscount: 30},
  }
}
